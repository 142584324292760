import { gql, useQuery } from "@apollo/client";
import { AtomSpinner, Card, Cell, Colors, ErrorPage, NoPermission, NotFound, OptionBar, OptionItem, Products, SchedulingRoles, StandardGrid, StyledCaption, StyledHeading, StyledParagraph, Typography, useAuthState, View } from "@barscience/global-components";
import { css, StyleSheet } from "aphrodite";
import { useState } from "react";
import { useParams } from "react-router-dom";
import StaffAvailabilityPage from "./profilePages/StaffAvailabilityPage";
import StaffSchedulesPage from "./profilePages/StaffSchedulesPage";
import StaffJobsPage from "./profilePages/StaffJobsPage";

/* Get User Profile Query */
const GET_USER_PROFILE = gql`
query getScheduleUserProfile($id: ID!) {
  scheduleUser(id: $id) {
    id
    firstName
    lastName
    email
    phone
    notes
    canManageUser
  }
}
`;

type GetUserProfileResponse = {
  scheduleUser: ScheduleUser;
}

export type ScheduleUser = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  notes: string | null;
  canManageUser: boolean;
}

export default function StaffProfile() {
  const { userId } = useParams();
  const { state } = useAuthState();
  const [selectedTab, setSelectedTab] = useState<string>('SUMMARY');
  const { data: userData, loading: userIsLoading, error: userError } = useQuery<GetUserProfileResponse>(GET_USER_PROFILE, {
    variables: {
      id: userId === 'me' ? state.user?.id : userId,
    },
  });

  if ((userId !== state.user?.id && userId !== 'me') && (state.user?.roles[Products.Scheduling]) !== SchedulingRoles.Admin && state.user?.roles[Products.Scheduling] !== SchedulingRoles.Manager) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (userIsLoading) {
    return (
      <StandardGrid>
        <Cell lg={12} md={8} sm={4}>
          <View>
            <AtomSpinner size='large' />
          </View>
        </Cell>
      </StandardGrid>
    );
  }

  if (userError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  if (!userData?.scheduleUser) {
    return (
      <StandardGrid>
        <NotFound />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '32px', flexWrap: 'wrap' }}>
          <span className={css(Typography.paragraph1, styles.initials)}>{userData.scheduleUser.firstName.charAt(0).toUpperCase()}{userData.scheduleUser.lastName.charAt(0).toUpperCase()}</span>

          <StyledHeading tag='h1'>{userData.scheduleUser.firstName} {userData.scheduleUser.lastName}</StyledHeading>
        </View>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <OptionBar selectedValue={selectedTab} onChange={setSelectedTab}>
          <OptionItem label='Summary' value='SUMMARY' />
          <OptionItem label='Jobs' value='JOBS' />
          <OptionItem label='Schedules' value='SCHEDULES' />
          <OptionItem label='Availability' value='AVAILABILITY' />
        </OptionBar>
      </Cell>

      {selectedTab === 'SUMMARY' && <SummaryPage user={userData.scheduleUser} />}
      {selectedTab === 'JOBS' && <StaffJobsPage user={userData.scheduleUser} />}
      {selectedTab === 'SCHEDULES' && <StaffSchedulesPage user={userData.scheduleUser} />}
      {selectedTab === 'AVAILABILITY' && <StaffAvailabilityPage user={userData.scheduleUser} />}
    </StandardGrid>
  );
}

type SummaryPageProps = {
  user: ScheduleUser;
}
function SummaryPage(props: SummaryPageProps) {
  return (
    <Cell lg={4} md={4} sm={4}>
      <Card>
        <View style={{ gap: '16px' }}>
          <StyledHeading tag='h6'>Contact Information</StyledHeading>
          <View>
            <StyledCaption>Email</StyledCaption>
            <StyledParagraph>{props.user.email}</StyledParagraph>
          </View>
          <View>
            <StyledCaption>Phone</StyledCaption>
            <StyledParagraph>{props.user.phone}</StyledParagraph>
          </View>
        </View>
      </Card>
    </Cell >
  );
}

const styles = StyleSheet.create({
  initials: {
    alignItems: 'center',
    backgroundColor: Colors.neutral600,
    border: `3px solid ${Colors.neutral100}`,
    borderRadius: '50%',
    color: '#ffffff',
    display: 'flex',
    fontSize: '32px',
    fontWeight: 700,
    height: '80px',
    justifyContent: 'center',
    width: '80px',
  },
});