import { gql, useMutation, useQuery } from "@apollo/client";
import { AtomSpinner, Breadcrumb, BreadcrumbGroup, Button, Card, Cell, Checkbox, Choice, ErrorPage, generateId, NoPermission, Products, SchedulingRoles, SingleSelect, StandardAlert, StandardGrid, StyledHeading, useAlertState, useAuthState, View } from "@barscience/global-components";
import { CSSProperties } from "aphrodite";
import { useState } from "react";
import { GET_SCHEDULE_SETTINGS, GetScheduleSettingsResponse, OrgSettings } from "../../components/config/OrgSettingsProvider";
import useOrgSettings from "../../components/config/useOrgSettings";

/* Update Hide End Time */
const UPDATE_HIDE_END_TIME = gql`
mutation updateScheduleHideEndTimeByDefaultSetting($hideByDefault: Boolean!) {
  updateScheduleHideEndTimeByDefaultSetting(hideByDefault: $hideByDefault) {
    orgId
    hideEndTimeByDefault
  }
}
`;

/* Update Start Day of Week */
const UPDATE_START_DAY_OF_WEEK = gql`
mutation updateScheduleStartDayOfWeekSetting($startDay: DayOfWeek!) {
  updateScheduleStartDayOfWeekSetting(startDay: $startDay) {
    orgId
    startDayOfWeek
  }
}
`;

/* Update Require Approval For Open Shift Pickups */
const UPDATE_REQUIRE_APPROVAL_FOR_OPEN_SHIFT_PICKUPS = gql`
mutation updateScheduleRequireApprovalForOpenShiftPickupsSetting($requireApproval: Boolean!) {
  updateScheduleRequireApprovalForOpenShiftPickupsSetting(requireApproval: $requireApproval) {
    orgId
    requireApprovalForOpenShiftPickups
  }
}
`;

/* Update Show Staff Contact Info */
const UPDATE_SHOW_STAFF_CONTACT_INFO = gql`
mutation updateScheduleShowStaffContactInfoSetting($shouldShow: Boolean!) {
  updateScheduleShowStaffContactInfoSetting(shouldShow: $shouldShow) {
    orgId
    showStaffContactInfo
  }
}
`;

/* Styles */
const cardStyle: CSSProperties = {
  maxWidth: '750px',
}

export default function AdvancedSettings() {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { updateSettings } = useOrgSettings();
  const [settings, setSettings] = useState<OrgSettings>({
    orgId: '',
    hideEndTimeByDefault: false,
    requireApprovalForOpenShiftPickups: false,
    startDayOfWeek: '',
    showStaffContactInfo: false,
  })
  const { data: settingsData, loading: settingsAreLoading, error: settingsError } = useQuery<GetScheduleSettingsResponse>(GET_SCHEDULE_SETTINGS, {
    onCompleted: (data) => {
      setSettings(data.orgScheduleSettings);
    }
  });
  const [updateHideEndTime] = useMutation(UPDATE_HIDE_END_TIME, {
    update(cache, { data }) {
      if (!data?.updateScheduleHideEndTimeByDefaultSetting || !settingsData?.orgScheduleSettings) {
        return;
      }

      cache.updateQuery<GetScheduleSettingsResponse>({ query: GET_SCHEDULE_SETTINGS }, (oldData) => {
        if (!oldData) {
          return;
        }

        return {
          orgScheduleSettings: {
            ...oldData.orgScheduleSettings,
            hideEndTimeByDefault: data.updateScheduleHideEndTimeByDefaultSetting.hideEndTimeByDefault,
          }
        }
      });
    },
  });
  const [updateStartDayOfWeek, { loading: updateStartDayOfWeekIsLoading }] = useMutation(UPDATE_START_DAY_OF_WEEK, {
    update(cache, { data }) {
      if (!data?.updateScheduleStartDayOfWeekSetting || !settingsData?.orgScheduleSettings) {
        return;
      }

      cache.updateQuery<GetScheduleSettingsResponse>({ query: GET_SCHEDULE_SETTINGS }, (oldData) => {
        if (!oldData) {
          return;
        }

        return {
          orgScheduleSettings: {
            ...oldData.orgScheduleSettings,
            startDayOfWeek: data.updateScheduleStartDayOfWeekSetting.startDayOfWeek,
          }
        }
      });
    },
  });
  const [updateRequireApprovalForOpenShiftPickups] = useMutation(UPDATE_REQUIRE_APPROVAL_FOR_OPEN_SHIFT_PICKUPS, {
    update(cache, { data }) {
      if (!data?.updateScheduleRequireApprovalForOpenShiftPickupsSetting || !settingsData?.orgScheduleSettings) {
        return;
      }

      cache.updateQuery<GetScheduleSettingsResponse>({ query: GET_SCHEDULE_SETTINGS }, (oldData) => {
        if (!oldData) {
          return;
        }

        return {
          orgScheduleSettings: {
            ...oldData.orgScheduleSettings,
            requireApprovalForOpenShiftPickups: data.updateScheduleRequireApprovalForOpenShiftPickupsSetting.requireApprovalForOpenShiftPickups,
          }
        }
      });
    },
  });
  const [updateShowStaffContactInfo] = useMutation(UPDATE_SHOW_STAFF_CONTACT_INFO, {
    update(cache, { data }) {
      if (!data?.updateScheduleShowStaffContactInfoSetting || !settingsData?.orgScheduleSettings) {
        return;
      }

      cache.updateQuery<GetScheduleSettingsResponse>({ query: GET_SCHEDULE_SETTINGS }, (oldData) => {
        if (!oldData) {
          return;
        }

        return {
          orgScheduleSettings: {
            ...oldData.orgScheduleSettings,
            showStaffContactInfo: data.updateScheduleShowStaffContactInfoSetting.showStaffContactInfo,
          }
        }
      });
    },
  });

  const handleChangeEndTimeDefault = async (_: string, value: boolean) => {
    setSettings(prev => ({ ...prev, hideEndTimeByDefault: value }));

    const { data, errors } = await updateHideEndTime({
      variables: {
        hideByDefault: value,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error updating end time default' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Changes saved' type='success' id={id} />
      addAlert(id, alert);

      updateSettings(data.updateScheduleHideEndTimeByDefaultSetting);
    }
  }

  const handleChangeStartDayOfWeek = async (_: string, value: string | null) => {
    if (!value) {
      return;
    }

    setSettings(prev => ({ ...prev, startDayOfWeek: value }));
  }

  const handleSaveStartDayOfWeek = async () => {
    if (settingsData?.orgScheduleSettings.startDayOfWeek === settings.startDayOfWeek) {
      return;
    }

    const { data, errors } = await updateStartDayOfWeek({
      variables: {
        startDay: settings.startDayOfWeek,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error updating start day of week' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Changes saved' type='success' id={id} />
      addAlert(id, alert);

      updateSettings(data?.updateScheduleStartDayOfWeekSetting);
    }
  }

  const handleChangeOpenShiftApproval = async (_: string, value: boolean) => {
    setSettings(prev => ({ ...prev, requireApprovalForOpenShiftPickups: value }));

    const { data, errors } = await updateRequireApprovalForOpenShiftPickups({
      variables: {
        requireApproval: value,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error updating open shift approval setting' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Changes saved' type='success' id={id} />
      addAlert(id, alert);

      updateSettings(data?.updateScheduleRequireApprovalForOpenShiftPickupsSetting);
    }
  }

  const handleChangeShowStaffContactInfo = async (_: string, value: boolean) => {
    setSettings(prev => ({ ...prev, showStaffContactInfo: value }));

    const { data, errors } = await updateShowStaffContactInfo({
      variables: {
        shouldShow: value,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error updating staff contact info setting' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Changes saved' type='success' id={id} />
      addAlert(id, alert);

      updateSettings(data?.updateScheduleShowStaffContactInfoSetting);
    }
  }

  if (state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin) {
    return (
      <StandardGrid>
        <NoPermission />
      </StandardGrid>
    );
  }

  if (settingsError) {
    return (
      <StandardGrid>
        <ErrorPage />
      </StandardGrid>
    );
  }

  return (
    <StandardGrid>
      <Cell lg={12} md={8} sm={4}>
        <BreadcrumbGroup>
          <Breadcrumb label='Settings' to='/settings' />
          <Breadcrumb label='Advanced Settings' />
        </BreadcrumbGroup>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        <StyledHeading tag='h3'>Advanced Settings</StyledHeading>
      </Cell>
      <Cell lg={12} md={8} sm={4}>
        {settingsAreLoading ?
          <View>
            <AtomSpinner size='medium' />
          </View>
          :
          <View style={{ gap: '24px' }}>
            <Card style={cardStyle}>
              <StyledHeading tag='h6'>Scheduling</StyledHeading>

              <View style={{ gap: '24px', marginTop: '16px' }}>
                <Checkbox name='hideEndTimeByDefault' label='Hide end time by default' description='The end time will be hidden by default when creating new shifts' checked={settings.hideEndTimeByDefault} onChange={handleChangeEndTimeDefault} />

                <View style={{ alignItems: 'flex-end', flexDirection: 'row', gap: '16px' }}>
                  <SingleSelect name='startDayOfWeek' label='Schedule start day' description='The day of week your schedule starts on' value={settings.startDayOfWeek} onChange={handleChangeStartDayOfWeek} style={{ maxWidth: '300px' }}>
                    <Choice label='Monday' value='MONDAY' />
                    <Choice label='Tuesday' value='TUESDAY' />
                    <Choice label='Wednesday' value='WEDNESDAY' />
                    <Choice label='Thursday' value='THURSDAY' />
                    <Choice label='Friday' value='FRIDAY' />
                    <Choice label='Saturday' value='SATURDAY' />
                    <Choice label='Sunday' value='SUNDAY' />
                  </SingleSelect>

                  <Button label='Save changes' variant='primary' role='button' action={handleSaveStartDayOfWeek} loading={updateStartDayOfWeekIsLoading} disabled={settingsData?.orgScheduleSettings.startDayOfWeek === settings.startDayOfWeek} />
                </View>
              </View>
            </Card>
            <Card style={cardStyle}>
              <StyledHeading tag='h6'>Shift Changes</StyledHeading>

              <View style={{ gap: '24px', marginTop: '16px' }}>
                <Checkbox name='requireApprovalForOpenPickups' label='Require approval for open shift pickups' description='Managers must approve pickups for open shifts' checked={settings.requireApprovalForOpenShiftPickups} onChange={handleChangeOpenShiftApproval} />
              </View>
            </Card>
            <Card style={cardStyle}>
              <StyledHeading tag='h6'>Staff</StyledHeading>

              <View style={{ gap: '24px', marginTop: '16px' }}>
                <Checkbox name='showStaffContactInfo' label='Allow all users to view staff contact info' description='Disabling will restrict staff contact info to Shift Leaders, Managers, and Admins' checked={settings.showStaffContactInfo} onChange={handleChangeShowStaffContactInfo} />
              </View>
            </Card>
          </View>
        }
      </Cell>
    </StandardGrid>
  );
}