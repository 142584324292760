import { AtomSpinner, Button, Card, Cell, Checkbox, Choice, CircularSpinner, Colors, ConfirmModal, ErrorPage, FormModal, generateId, HasProductRole, Icons, ModalLauncher, Products, SchedulingRoles, SingleSelect, StandardAlert, StyledParagraph, Table, TableBody, TableCell, TableRow, Tooltip, useAlertState, useAuthState, View } from "@barscience/global-components";
import { ScheduleUser } from "../StaffProfile";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { GET_ALL_SCHEDULES, GetAllSchedulesResponse } from "../../settings/Schedules";
import { useEffect } from "react";
import { GET_CURRENT_USER_SCHEDULES, GetCurrentUserSchedulesResponse } from "../../../util/getUserScheduleAssignments";

/* Get Schedules Query */
const GET_USER_SCHEDULES = gql`
query getUserAssignedSchedules($userId: ID!, $includeViewOnly: Boolean!) {
  scheduleUser(id: $userId) {
    id
    schedules(includeViewOnly: $includeViewOnly) {
      schedule {
        id
        name
      }
      showOnSchedule
      canEdit
      canPublish
    }
    canManageUser
  }
}
`;

type GetUserSchedulesResponse = {
  scheduleUser: {
    id: string;
    schedules: UserSchedule[];
    canManageUser: boolean;
  } | null;
}

type UserSchedule = {
  schedule: {
    id: string;
    name: string;
    __typename: "Schedule";
  };
  showOnSchedule: boolean;
  canEdit: boolean | null;
  canPublish: boolean | null;
  __typename: "UserScheduleAssignment";
}

/* Add Schedule Mutation */
const ADD_USER_TO_SCHEDULE = gql`
mutation addUserToSchedule($userId: ID!, $scheduleId: ID!, $includeViewOnly: Boolean!) {
  addUserToSchedule(userId: $userId, scheduleId: $scheduleId) {
    id
    schedules(includeViewOnly: $includeViewOnly) {
      schedule {
        id
        name
      }
      showOnSchedule
      canEdit
      canPublish
    }
  }
}
`;

type AddToScheduleInput = {
  scheduleId: string;
}

/* Edit Schedule Mutation */
const EDIT_SCHEDULE = gql`
mutation editUserScheduleAssignment($userId: ID!, $scheduleId: ID!, $showOnSchedule: Boolean!, $includeViewOnly: Boolean!) {
  editUserScheduleAssignment(userId: $userId, scheduleId: $scheduleId, showOnSchedule: $showOnSchedule) {
    id
    schedules(includeViewOnly: $includeViewOnly) {
      schedule {
        id
        name
      }
      showOnSchedule
      canEdit
      canPublish
    }
  }
}
`;

type EditUserScheduleAssignmentResponse = {
  editUserScheduleAssignment: {
    id: string;
    schedules: UserSchedule[];
  } | null;
}

/* Remove Schedule Mutation */
const REMOVE_USER_FROM_SCHEDULE = gql`
mutation removeUserFromSchedule($userId: ID!, $scheduleId: ID!, $includeViewOnly: Boolean!) {
  removeUserFromSchedule(userId: $userId, scheduleId: $scheduleId) {
    id
    schedules(includeViewOnly: $includeViewOnly) {
      canEdit
      canPublish
      showOnSchedule
      schedule {
        id
        name
      }
    }
  }
}
`;

/* Update Permissions */
const UPDATE_USER_PERMISSIONS = gql`
mutation updateUserSchedulePermissions($userId: ID!, $scheduleId: ID!, $role: ScheduleRole, $includeViewOnly: Boolean!) {
  updateUserSchedulePermissions(userId: $userId, scheduleId: $scheduleId, role: $role) {
    id
    schedules(includeViewOnly: $includeViewOnly) {
      schedule {
        id
        name
      }
      canEdit
      canPublish
      showOnSchedule
    }
  }
}
`;

type UpdateUserSchedulePermissionsResponse = {
  updateUserSchedulePermissions: {
    id: string;
    schedules: {
      schedule: {
        id: string;
        name: string;
        __typename: "Schedule";
      };
      canEdit: boolean | null;
      canPublish: boolean | null;
      showOnSchedule: boolean;
      __typename: "UserScheduleAssignment";
    }[];
    __typename: "ScheduleUser";
  } | null;
}

/* Page Types */
type StaffSchedulesPageProps = {
  user: ScheduleUser;
}

export default function StaffSchedulesPage(props: StaffSchedulesPageProps) {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const { data: currentUserScheduleData, loading: currentUserSchedulesAreLoading, error: currentUserScheduleError } = useQuery<GetCurrentUserSchedulesResponse>(GET_CURRENT_USER_SCHEDULES, {
    variables: {
      id: state.user?.id,
      includeViewOnly: true,
    },
  });
  const { data: userScheduleData, loading: userSchedulesAreLoading, error: userScheduleError } = useQuery<GetUserSchedulesResponse>(GET_USER_SCHEDULES, {
    variables: {
      userId: props.user.id,
      includeViewOnly: true,
    },
  });
  const [getAllSchedules, { data: allScheduleData, loading: allSchedulesAreLoading, error: allSchedulesError }] = useLazyQuery<GetAllSchedulesResponse>(GET_ALL_SCHEDULES);
  const [addUserToSchedule] = useMutation(ADD_USER_TO_SCHEDULE);
  const [editScheduleAssignment, { loading: editScheduleAssignmentIsLoading }] = useMutation<EditUserScheduleAssignmentResponse>(EDIT_SCHEDULE, {
    optimisticResponse(vars, { IGNORE }) {
      if (!vars.scheduleId || !userScheduleData?.scheduleUser?.schedules) {
        return IGNORE;
      }

      const schedules: UserSchedule[] = [];

      userScheduleData.scheduleUser.schedules.forEach((schedule) => {
        schedules.push({
          schedule: {
            id: schedule.schedule.id,
            name: schedule.schedule.name,
            __typename: "Schedule",
          },
          showOnSchedule: vars.scheduleId === schedule.schedule.id ? vars.showOnSchedule : schedule.showOnSchedule,
          canEdit: schedule.canEdit,
          canPublish: schedule.canPublish,
          __typename: "UserScheduleAssignment",
        });
      });


      return {
        editUserScheduleAssignment: {
          id: vars.userId,
          schedules: schedules,
          __typename: "ScheduleUser",
        },
      };
    }
  });
  const [removeUserFromSchedule] = useMutation(REMOVE_USER_FROM_SCHEDULE);
  const [updatePermissions, { loading: updatePermissionsIsLoading }] = useMutation<UpdateUserSchedulePermissionsResponse>(UPDATE_USER_PERMISSIONS, {
    optimisticResponse(vars, { IGNORE }) {
      if (!vars.scheduleId || !userScheduleData?.scheduleUser?.schedules) {
        return IGNORE;
      }

      const schedules: UserSchedule[] = [];

      userScheduleData.scheduleUser.schedules.forEach((schedule) => {
        schedules.push({
          schedule: {
            id: schedule.schedule.id,
            name: schedule.schedule.name,
            __typename: "Schedule",
          },
          canEdit: schedule.schedule.id === vars.scheduleId ? vars.role === 'EDITOR' || vars.role === 'PUBLISHER' : schedule.canEdit,
          canPublish: schedule.schedule.id === vars.scheduleId ? vars.role === 'PUBLISHER' : schedule.canPublish,
          showOnSchedule: schedule.showOnSchedule,
          __typename: "UserScheduleAssignment",
        })
      });

      return {
        updateUserSchedulePermissions: {
          id: vars.userId,
          schedules: schedules,
          __typename: "ScheduleUser",
        },
      };
    }
  });

  useEffect(() => {
    if (state.user?.roles[Products.Scheduling] === SchedulingRoles.Admin) {
      getAllSchedules({
        variables: {
          isArchived: false
        },
      });
    }
  }, [state.user, getAllSchedules])

  const availableSchedules = allScheduleData?.schedules?.filter((schedule) => {
    return !userScheduleData?.scheduleUser?.schedules.some((userSchedule) => {
      return userSchedule.schedule.id === schedule.id
    });
  });

  /* Add User To Schedule */
  const handleAddUserToSchedule = async (values: AddToScheduleInput) => {
    const { errors } = await addUserToSchedule({
      variables: {
        userId: props.user.id,
        scheduleId: values.scheduleId,
        includeViewOnly: true
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to add user to schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const addToScheduleModal = (
    <FormModal<AddToScheduleInput> title='Add to Schedule' initialValues={{ scheduleId: '' }} onSubmit={handleAddUserToSchedule} submitLabel='Add To Schedule'>
      <View>
        {allSchedulesAreLoading ?
          <View style={{ alignItems: 'center', justifyContent: 'center' }}>
            <CircularSpinner size='medium' />
          </View>
          :
          <SingleSelect label='Select a schedule' name='scheduleId' required>
            {availableSchedules?.map((schedule) => {
              return (
                <Choice label={schedule.name} value={schedule.id} key={schedule.id} />
              );
            })}
          </SingleSelect>
        }
      </View>
    </FormModal>
  );

  /* Edit Show On Schedule */
  const handleEditShowOnSchedule = async (name: string, value: boolean) => {
    const scheduleId = name.split('-show-on-schedule')[0];

    const { errors } = await editScheduleAssignment({
      variables: {
        userId: props.user.id,
        scheduleId: scheduleId,
        showOnSchedule: value,
        includeViewOnly: true
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to edit schedule assignment' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  /* Remove User From Schedule */
  const handleRemoveUserFromSchedule = async (scheduleId: string) => {
    const { errors } = await removeUserFromSchedule({
      variables: {
        userId: props.user.id,
        scheduleId: scheduleId,
        includeViewOnly: true
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove user from schedule' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  const removeFromScheduleModal = (
    <ConfirmModal title='Remove from schedule?' onConfirm={handleRemoveUserFromSchedule} confirmLabel='Remove' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This user will be removed from this schedule. Existing and previous shifts for this user will not be affected.</StyledParagraph>
      </View>
    </ConfirmModal>
  )

  /* Update Permissions */
  const handleUpdatePermissions = async (name: string, value: string | null) => {
    const scheduleId = name.split('-role')[0];

    const { errors } = await updatePermissions({
      variables: {
        userId: props.user.id,
        scheduleId: scheduleId,
        role: value === 'VIEWER' ? null : value,
        includeViewOnly: true
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to edit schedule permissions' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    }
  }

  if (userSchedulesAreLoading || currentUserSchedulesAreLoading) {
    return (
      <Cell lg={12} md={8} sm={4}>
        <View>
          <AtomSpinner size='medium' />
        </View>
      </Cell>
    );
  }

  if (userScheduleError || allSchedulesError || currentUserScheduleError) {
    return (
      <ErrorPage />
    );
  }

  return (
    <Cell lg={state.user?.roles[Products.Scheduling] === SchedulingRoles.Admin ? 12 : 6} md={6} sm={4}>
      <Card>
        <View style={{ gap: '16px' }}>
          {userScheduleData?.scheduleUser?.canManageUser &&
            <View style={{ alignItems: 'flex-end', justifyContent: 'center' }}>
              {(editScheduleAssignmentIsLoading || updatePermissionsIsLoading) ?
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '8px' }}>
                  <CircularSpinner size='xsmall' />
                  <StyledParagraph style={{ color: Colors.neutral700 }}>Saving changes...</StyledParagraph>
                </View>
                :
                <StyledParagraph style={{ color: Colors.neutral700 }}>All changes saved</StyledParagraph>
              }
            </View>
          }

          {(userScheduleData?.scheduleUser?.schedules.length || 0) > 0 ?
            <Table>
              <TableBody>
                {userScheduleData?.scheduleUser?.schedules.map((schedule) => {
                  return (
                    <TableRow key={schedule.schedule.id}>
                      <TableCell><span style={{ fontWeight: 'bold' }}>{schedule.schedule.name}</span></TableCell>
                      <TableCell>
                        <Checkbox label='Show on schedule' name={`${schedule.schedule.id}-show-on-schedule`} checked={schedule.showOnSchedule} onChange={handleEditShowOnSchedule} disabled={state.user?.roles[Products.Scheduling] !== SchedulingRoles.Admin && !currentUserScheduleData?.scheduleUser?.schedules.some((userSchedule) => userSchedule.schedule.id === schedule.schedule.id && userSchedule.canEdit)} />
                      </TableCell>
                      <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
                        <TableCell style={{ padding: '16px 0', width: '240px' }}>
                          <SingleSelect name={`${schedule.schedule.id}-role`} value={schedule.canPublish ? 'PUBLISHER' : (schedule.canEdit ? 'EDITOR' : 'VIEWER')} onChange={handleUpdatePermissions} style={{ maxWidth: '240px', minWidth: '240px' }}>
                            <Choice label='Viewer' description='The default role for employees' value='VIEWER' />
                            <Choice label='Editor' description='Can modify this schedule but cannot publish changes for employees to see' value='EDITOR' />
                            <Choice label='Publisher' description='Can modify and publish this schedule' value='PUBLISHER' />
                          </SingleSelect>
                        </TableCell>
                        <TableCell style={{ paddingLeft: '24px' }}>
                          <ModalLauncher modal={removeFromScheduleModal}>
                            {({ openModal }) => (
                              <Button label='Remove from schedule' variant='tertiary' role='button' action={() => { openModal(schedule.schedule.id); }} destructive />
                            )}
                          </ModalLauncher>
                        </TableCell>
                      </HasProductRole>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
            :
            <StyledParagraph style={{ color: Colors.neutral700 }}>This user is not assigned to any schedules.</StyledParagraph>
          }

          <HasProductRole product={Products.Scheduling} roles={[SchedulingRoles.Admin]}>
            <Tooltip content='There are no schedules that the user is not assigned to' disabled={(availableSchedules?.length || 0) > 0}>
              <ModalLauncher modal={addToScheduleModal}>
                {({ openModal }) => (
                  <Button label='Add to Schedule' variant='tertiary' leftIcon={Icons.Plus} role='button' action={openModal} disabled={availableSchedules?.length === 0} />
                )}
              </ModalLauncher>
            </Tooltip>
          </HasProductRole>
        </View>
      </Card>
    </Cell >
  );
}