import { gql } from "@apollo/client";

/* Get User Schedules Query */
export const GET_CURRENT_USER_SCHEDULES = gql`
query getCurrentUserSchedules($id: ID!, $includeViewOnly: Boolean!) {
  scheduleUser(id: $id) {
    id
    schedules(includeViewOnly: $includeViewOnly) {
      schedule {
        id
        name
      }
      showOnSchedule
      canEdit
      canPublish
    }
  }
}
`;

export type GetCurrentUserSchedulesResponse = {
  scheduleUser: {
    id: string;
    schedules: {
      schedule: {
        id: string;
        name: string;
      };
      showOnSchedule: boolean;
      canEdit: boolean | null;
      canPublish: boolean | null;
    }[];
  } | null;
}