import { ActionItem, ActionMenu, AtomSpinner, Button, Card, Cell, Checkbox, CheckboxGroup, CircularSpinner, Colors, ConfirmModal, createStyle, DatePicker, ErrorPage, FormModal, generateId, Icon, Icons, ModalLauncher, Products, SchedulingRoles, StandardAlert, StyledHeading, StyledParagraph, Table, TableBody, TableCell, TableHeader, TableHeaderCell, TableRow, TextField, Tooltip, Typography, useAlertState, useAuthState, View } from "@barscience/global-components";
import { ScheduleUser } from "../StaffProfile";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { css } from "aphrodite";

/* Get User Jobs Query */
const GET_USER_JOBS = gql`
query getScheduleUserJobsAndSchedules($id: ID!, $includeViewOnly: Boolean!) {
  scheduleUser(id: $id) {
    jobs {
      job {
        id
        name
        stations {
          station {
            id
            name
            isArchived
          }
        }
        isArchived
        defaultRate
      }
      isDefault
      stations {
        station {
          id
          name
        }
      }
    }
    schedules(includeViewOnly: $includeViewOnly) {
      schedule {
        jobs {
          id
          name
          isArchived
        }
      }
    }
  }
}
`;

type GetUserJobsResponse = {
  scheduleUser: {
    jobs: JobAssignment[];
    schedules: Schedule[];
  };
}

type JobAssignment = {
  job: {
    id: string;
    name: string;
    isArchived: boolean;
    defaultRate: string | null;
    stations: {
      station: {
        id: string;
        name: string;
        isArchived: boolean;
      };
    }[];
  };
  isDefault: boolean;
  stations: {
    station: {
      id: string;
      name: string;
    };
  }[];
}

type Schedule = {
  schedule: {
    jobs: {
      id: string;
      name: string;
      isArchived: boolean;
    }[];
  }
}

/* Assign Job Mutation */
const ASSIGN_JOB_TO_USER = gql`
mutation addUserToJob($userId: ID!, $jobId: ID!) {
  addUserToJob(userId: $userId, jobId: $jobId) {
    id
  }
}
`;

type AssignJobsInput = {
  jobs: { [jobId: string]: boolean; };
}

/* Unassign Job Mutation */
const UNASSIGN_JOB_FROM_USER = gql`
mutation removeUserFromJob($userId: ID!, $jobId: ID!) {
  removeUserFromJob(userId: $userId, jobId: $jobId) {
    id
  }
}
`;

/* Set Default Job Mutation */
const SET_DEFAULT_JOB = gql`
mutation setUserDefaultJob($userId: ID!, $jobId: ID!) {
  setUserDefaultJob(userId: $userId, jobId: $jobId) {
    id
  }
}
`;

/* Assign User To Station Mutation */
const ASSIGN_USER_TO_STATION = gql`
mutation addUserToStation($userId: ID!, $jobId: ID!, $stationId: ID!) {
  addUserToStation(userId: $userId, jobId: $jobId, stationId: $stationId) {
    id
  }
}
`;

type AssignStationsInput = {
  stations: { [stationId: string]: boolean; };
}

/* Unassign User From Station Mutation */
const UNASSIGN_USER_FROM_STATION = gql`
mutation removeUserFromStation($userId: ID!, $jobId: ID!, $stationId: ID!) {
  removeUserFromStation(userId: $userId, jobId: $jobId, stationId: $stationId) {
    id
  }
}
`;

/* Get Pay Rate History */
const GET_PAY_RATE_HISTORY = gql`
query getUserPayRateHistoryForJob($jobId: ID!, $userId: ID!) {
  scheduleUser(id: $userId) {
    payRateHistory(jobId: $jobId) {
      job {
        id
      }
      hourlyPayRate
      effectiveDate
    }
  }
}
`;

type GetPayRateHistoryResponse = {
  scheduleUser: {
    payRateHistory: {
      job: {
        id: string;
      };
      hourlyPayRate: string;
      effectiveDate: string;
    }[];
  };
}

/* Create Pay Rate */
const CREATE_PAY_RATE = gql`
mutation createUserPayRate($userId: ID!, $jobId: ID!, $effectiveDate: Date!, $payRate: Decimal!) {
  success: createUserPayRate(userId: $userId, jobId: $jobId, effectiveDate: $effectiveDate, payRate: $payRate)
}
`;

type CreatePayRateInput = {
  effectiveDate: string;
  payRate: string;
}

/* Delete Pay Rate */
const DELETE_PAY_RATE = gql`
mutation deleteUserPayRate($userId: ID!, $jobId: ID!, $effectiveDate: Date!) {
  success: deleteUserPayRate(userId: $userId, jobId: $jobId, effectiveDate: $effectiveDate)
}
`;

/* Page Types */
type StaffJobsPageProps = {
  user: ScheduleUser;
}

export default function StaffJobsPage(props: StaffJobsPageProps) {
  const { addAlert } = useAlertState();
  const [assignableJobs, setAssignableJobs] = useState<{ id: string; name: string; }[]>([]);
  const { data: userJobsData, loading: userJobsAreLoading, error: userJobsError, refetch: refetchUserJobs } = useQuery<GetUserJobsResponse>(GET_USER_JOBS, {
    variables: {
      id: props.user.id,
      includeViewOnly: true
    },
  });
  const [assignUserToJob] = useMutation(ASSIGN_JOB_TO_USER);

  useEffect(() => {
    if (userJobsData) {
      const allJobs: { [jobId: string]: { id: string; name: string; } } = {};

      userJobsData.scheduleUser.schedules.forEach((schedule) => {
        schedule.schedule.jobs.forEach((job) => {
          if (!job.isArchived) {
            allJobs[job.id] = { id: job.id, name: job.name };
          }
        });
      });

      userJobsData.scheduleUser.jobs.forEach((jobAssignment) => {
        if (allJobs[jobAssignment.job.id]) {
          delete allJobs[jobAssignment.job.id];
        }
      });

      setAssignableJobs(Object.values(allJobs).sort((a, b) => a.name.localeCompare(b.name)));
    }
  }, [userJobsData]);

  /* Assign Job */
  const handleAssignJob = async (values: AssignJobsInput) => {
    const jobsToAssign = Object.keys(values.jobs).filter((jobId) => values.jobs[jobId]);

    let errorCount = 0;

    if (jobsToAssign.length === 0) {
      return;
    }

    for (const jobId of jobsToAssign) {
      const { errors } = await assignUserToJob({
        variables: {
          userId: props.user.id,
          jobId: jobId,
        },
      });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to assign job' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
        errorCount++;
      }
    }

    await refetchUserJobs({
      variables: {
        id: props.user.id,
        includeViewOnly: true,
      },
    });

    const id = generateId();
    const alert = <StandardAlert title={`Assigned ${jobsToAssign.length - errorCount} job${(jobsToAssign.length - errorCount) === 1 ? '' : 's'}`} type='success' id={id} />
    addAlert(id, alert);
  }

  const assignJobModal = (
    <FormModal<AssignJobsInput> title='Assign Jobs' onSubmit={handleAssignJob} initialValues={{ jobs: {} }} submitLabel='Assign Jobs'>
      <View style={{ gap: '16px' }}>
        <CheckboxGroup name='jobs' label='Select jobs to assign:'>
          {assignableJobs.map((job) => (
            <Checkbox key={job.id} name={job.id} label={job.name} />
          ))}
        </CheckboxGroup>
      </View>
    </FormModal>
  );

  if (userJobsAreLoading) {
    return (
      <Cell lg={12} md={8} sm={4}>
        <View>
          <AtomSpinner size='medium' />
        </View>
      </Cell>
    );
  }

  if (userJobsError) {
    return (
      <ErrorPage />
    );
  }

  return (
    <>
      <Cell lg={8} md={8} sm={4}>
        <View style={{ flexDirection: 'row', flexWrap: 'wrap', gap: '16px', justifyContent: 'space-between' }}>
          <StyledHeading tag='h4'>Assigned Jobs</StyledHeading>

          <ModalLauncher modal={assignJobModal}>
            {({ openModal }) => (
              <Tooltip content='There are no jobs available to assign to this user.' disabled={assignableJobs.length > 0}>
                <Button label='Assign Jobs' variant='primary' role='button' action={openModal} disabled={assignableJobs.length === 0} />
              </Tooltip>
            )}
          </ModalLauncher>
        </View>
      </Cell>
      <Cell lg={8} md={8} sm={4}>
        {userJobsData?.scheduleUser.jobs.length === 0 ?
          <Card size='medium'>
            <View style={{ alignItems: 'center', gap: '16px', justifyContent: 'center' }}>
              <StyledParagraph>This employee does not have any jobs assigned to them.</StyledParagraph>
              <ModalLauncher modal={assignJobModal}>
                {({ openModal }) => (
                  <Tooltip content='There are no jobs available to assign to this user.' disabled={assignableJobs.length > 0}>
                    <Button label='Assign Jobs' variant='primary' role='button' action={openModal} disabled={assignableJobs.length === 0} />
                  </Tooltip>
                )}
              </ModalLauncher>
            </View>
          </Card>
          :
          <View style={{ gap: '16px' }}>
            {userJobsData?.scheduleUser.jobs.map((jobAssignment) => {
              return (
                <UserJobCard key={jobAssignment.job.id} user={props.user} jobAssignment={jobAssignment} refetchUserJobs={refetchUserJobs} />
              );
            })}
          </View>
        }
      </Cell>
    </>
  );
}

type UserJobCardProps = {
  user: ScheduleUser;
  jobAssignment: JobAssignment;
  refetchUserJobs: any;
}

function UserJobCard(props: UserJobCardProps) {
  const { state } = useAuthState();
  const { addAlert } = useAlertState();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isShowingPayRates, setIsShowingPayRates] = useState<boolean>(false);
  const [availableStations, setAvailableStations] = useState<{ id: string; name: string; }[]>([]);
  const [loadPayRates, { data: payData, loading: payRatesAreLoading }] = useLazyQuery<GetPayRateHistoryResponse>(GET_PAY_RATE_HISTORY);
  const [removeUserFromJob] = useMutation(UNASSIGN_JOB_FROM_USER);
  const [setUserDefaultJob] = useMutation(SET_DEFAULT_JOB);
  const [assignUserToStation] = useMutation(ASSIGN_USER_TO_STATION);
  const [removeUserFromStation] = useMutation(UNASSIGN_USER_FROM_STATION);
  const [createPayRate] = useMutation(CREATE_PAY_RATE, { refetchQueries: [{ query: GET_PAY_RATE_HISTORY, variables: { jobId: props.jobAssignment.job.id, userId: props.user.id } }] });
  const [deletePayRate] = useMutation(DELETE_PAY_RATE, { refetchQueries: [{ query: GET_PAY_RATE_HISTORY, variables: { jobId: props.jobAssignment.job.id, userId: props.user.id } }] });

  useEffect(() => {
    const allStations: { [stationId: string]: { id: string; name: string; } } = {};

    props.jobAssignment.job.stations.forEach((station) => {
      if (!station.station.isArchived) {
        allStations[station.station.id] = { id: station.station.id, name: station.station.name };
      }
    });

    props.jobAssignment.stations.forEach((station) => {
      if (allStations[station.station.id]) {
        delete allStations[station.station.id];
      }
    });

    setAvailableStations(Object.values(allStations).sort((a, b) => a.name.localeCompare(b.name)));
  }, [props.jobAssignment]);

  /* Remove Job */
  const handleRemoveJob = async (jobId: string) => {
    const { errors } = await removeUserFromJob({
      variables: {
        userId: props.user.id,
        jobId: jobId,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to remove job' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
      return;
    }

    await props.refetchUserJobs({
      variables: {
        id: props.user.id,
        includeViewOnly: true,
      }
    });

    const id = generateId();
    const alert = <StandardAlert title='Job removed' type='success' id={id} />
    addAlert(id, alert);
  }

  const removeJobModal = (
    <ConfirmModal title='Remove job?' onConfirm={handleRemoveJob} confirmLabel='Remove Job' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>The employee will be unassigned from this job. You can always reassign the job later. Their station assignments and pay rate history will be preserved.</StyledParagraph>
        <StyledParagraph>You can still assign shifts using this job to the employee, but they will not be able to pick up or swap shifts for this job.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Set Default Job */
  const handleSetDefaultJob = async (jobId: string) => {
    const { errors } = await setUserDefaultJob({
      variables: {
        userId: props.user.id,
        jobId: jobId,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to set default job' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
      return;
    }

    await props.refetchUserJobs({
      variables: {
        id: props.user.id,
        includeViewOnly: true,
      }
    });

    const id = generateId();
    const alert = <StandardAlert title='Default job updated' type='success' id={id} />
    addAlert(id, alert);
  }

  const setDefaultJobModal = (
    <ConfirmModal title='Change default job?' onConfirm={handleSetDefaultJob} confirmLabel='Change Default Job'>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This employee's default job will be updated.</StyledParagraph>
        <StyledParagraph>The default job is used to indicate what types of shifts the employee should primarily be scheduled.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Assign Stations */
  const handleAssignStations = async (values: AssignStationsInput) => {
    const stationsToAssign = Object.keys(values.stations).filter((stationId) => values.stations[stationId]);
    let errorCount = 0;

    if (stationsToAssign.length === 0) {
      return;
    }

    for (const stationId of stationsToAssign) {
      const { errors } = await assignUserToStation({
        variables: {
          userId: props.user.id,
          jobId: props.jobAssignment.job.id,
          stationId: stationId,
        },
      });

      if (errors) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to assign station' description={errors[0].message} type='error' id={id} />
        addAlert(id, alert);
        errorCount++;
      }
    }

    await props.refetchUserJobs({
      variables: {
        id: props.user.id,
        includeViewOnly: true,
      }
    });

    const id = generateId();
    const alert = <StandardAlert title={`Assigned ${stationsToAssign.length - errorCount} station${(stationsToAssign.length - errorCount) === 1 ? '' : 's'}`} type='success' id={id} />
    addAlert(id, alert);
  }

  const assignStationsModal = (
    <FormModal<AssignStationsInput> title='Assign Stations' onSubmit={handleAssignStations} initialValues={{ stations: {} }} submitLabel='Assign Stations'>
      <View style={{ gap: '16px' }}>
        <CheckboxGroup name='stations' label='Select stations to assign:'>
          {availableStations.map((station) => (
            <Checkbox key={station.id} name={station.id} label={station.name} />
          ))}
        </CheckboxGroup>
      </View>
    </FormModal>
  );

  /* Unassign Station */
  const handleUnassignStation = async (stationId: string) => {
    const { errors } = await removeUserFromStation({
      variables: {
        userId: props.user.id,
        jobId: props.jobAssignment.job.id,
        stationId: stationId,
      }
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Failed to unassign station' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
      return;
    }

    await props.refetchUserJobs({
      variables: {
        id: props.user.id,
        includeViewOnly: true,
      }
    });

    const id = generateId();
    const alert = <StandardAlert title='Station unassigned' type='success' id={id} />
    addAlert(id, alert);
  }

  const unassignStationModal = (
    <ConfirmModal title='Unassign Station?' onConfirm={handleUnassignStation} confirmLabel='Remove' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>This employee will be removed from this station. They will not be able to pick up or swap shifts for this station.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  /* Load Pay Rates */
  const handleLoadPayRates = () => {
    loadPayRates({
      variables: {
        userId: props.user.id,
        jobId: props.jobAssignment.job.id,
      },
    }).then((result) => {
      if (result.error) {
        const id = generateId();
        const alert = <StandardAlert title='Failed to load pay rates' description={result.error.message} type='error' id={id} />
        addAlert(id, alert);
      }
    });
  }

  /* Create Pay Rate */
  const handleCreatePayRate = async (values: CreatePayRateInput) => {
    const { errors } = await createPayRate({
      variables: {
        userId: props.user.id,
        jobId: props.jobAssignment.job.id,
        payRate: values.payRate,
        effectiveDate: values.effectiveDate,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error creating pay rate' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Pay rate created' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const createPayRateModal = (
    <FormModal<CreatePayRateInput> title='Create Pay Rate' onSubmit={handleCreatePayRate} submitLabel='Create' initialValues={{ payRate: '', effectiveDate: '' }}>
      <View style={{ gap: '16px' }}>
        <TextField label='Hourly Pay Rate' type='currency' name='payRate' required />
        <DatePicker label='Effective Date' name='effectiveDate' required dateFilter={(date) => {
          const existingDates = payData?.scheduleUser.payRateHistory.map((payRate) => new Date(payRate.effectiveDate)) || [];
          if (existingDates.includes(date)) {
            return false;
          }

          return true;
        }} />
      </View>
    </FormModal>
  );

  /* Delete Pay Rate */
  const handleDeletePayRate = async (effectiveDate: string) => {
    const { errors } = await deletePayRate({
      variables: {
        userId: props.user.id,
        jobId: props.jobAssignment.job.id,
        effectiveDate: effectiveDate,
      },
    });

    if (errors) {
      const id = generateId();
      const alert = <StandardAlert title='Error deleting pay rate' description={errors[0].message} type='error' id={id} />
      addAlert(id, alert);
    } else {
      const id = generateId();
      const alert = <StandardAlert title='Pay rate deleted' type='success' id={id} />
      addAlert(id, alert);
    }
  }

  const deletePayRateModal = (
    <ConfirmModal title='Delete Pay Rate?' onConfirm={handleDeletePayRate} confirmLabel='Delete' destructive>
      <View style={{ gap: '16px' }}>
        <StyledParagraph>All shifts after this pay rate's effective date will use the previous pay rate. This will effect historical data and reports.</StyledParagraph>
      </View>
    </ConfirmModal>
  );

  return (
    <Card style={{ boxSizing: 'border-box', width: '100%' }}>
      <View style={{ gap: '16px' }}>
        <View style={{ alignItems: 'center', flexDirection: 'row', gap: '24px', justifyContent: 'space-between' }}>
          <View style={{ alignItems: 'center', cursor: 'pointer', flexDirection: 'row', gap: '16px', width: 'fit-content' }} onClick={() => { setIsOpen(!isOpen); setIsShowingPayRates(false); }}>
            {isOpen ? <Icon icon={Icons.ChevronDown} size='small' key='icon-open' /> : <Icon icon={Icons.ChevronRight} size='small' key='icon-closed' />}
            <StyledHeading tag='h5' style={{ 'user-select': 'none' }}>{props.jobAssignment.job.name}</StyledHeading>
            {props.jobAssignment.isDefault && <span className={css(Typography.paragraph1, createStyle({ backgroundColor: Colors.primary500, color: '#ffffff', fontWeight: 600, padding: '4px 8px', borderRadius: '4px' }))}>Default</span>}
          </View>

          {props.user.canManageUser &&
            <ModalLauncher modal={removeJobModal}>
              {({ openModal }) => (
                <ModalLauncher modal={setDefaultJobModal}>
                  {({ openModal: openSetDefaultJobModal }) => (
                    <ActionMenu>
                      <ActionItem label='Mark as default' onClick={() => { openSetDefaultJobModal(props.jobAssignment.job.id); }} />
                      <ActionItem label='Unassign job' onClick={() => { openModal(props.jobAssignment.job.id); }} />
                    </ActionMenu>
                  )}
                </ModalLauncher>
              )}
            </ModalLauncher>
          }
        </View>

        {isOpen &&
          <View style={{ marginLeft: '32px', gap: '16px' }}>
            <View style={{ marginTop: '24px' }}>
              <StyledHeading tag='h6'>Assigned Stations</StyledHeading>

              {props.jobAssignment.stations.length === 0 ?
                <StyledParagraph style={{ color: Colors.neutral700 }}>This employee is not assigned to any stations for this job.</StyledParagraph>
                :
                <View style={{ maxWidth: '100%', overflow: 'auto' }}>
                  <Table>
                    <TableBody>
                      {props.jobAssignment.stations.map((station) => {
                        return (
                          <TableRow key={station.station.id}>
                            <TableCell>{station.station.name}</TableCell>
                            {props.user.canManageUser &&
                              <TableCell>
                                <ModalLauncher modal={unassignStationModal}>
                                  {({ openModal }) => (
                                    <Button label='Remove' variant='tertiary' role='button' destructive action={() => { openModal(station.station.id); }} />
                                  )}
                                </ModalLauncher>
                              </TableCell>
                            }
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </View>
              }

              <ModalLauncher modal={assignStationsModal}>
                {({ openModal }) => (
                  <Tooltip content='There are no available stations to assign.' disabled={availableStations.length > 0}>
                    <Button label='Assign Stations' variant='tertiary' role='button' action={openModal} leftIcon={Icons.Plus} iconSize='small' disabled={availableStations.length === 0} />
                  </Tooltip>
                )}
              </ModalLauncher>
            </View>
            {(props.user.canManageUser || state.user?.roles[Products.Scheduling] === SchedulingRoles.Admin) && <View>
              <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px', justifyContent: 'space-between' }}>
                <View style={{ alignItems: 'center', flexDirection: 'row', gap: '16px' }}>
                  <StyledHeading tag='h6'>Pay Rates</StyledHeading>
                  {isShowingPayRates ?
                    <Button label='Hide pay rates' variant='tertiary' role='button' action={() => { setIsShowingPayRates(false); }} />
                    :
                    <Button label='Show pay rates' variant='tertiary' role='button' action={() => { setIsShowingPayRates(true); handleLoadPayRates(); }} />
                  }
                </View>

                {isShowingPayRates &&
                  <ModalLauncher modal={createPayRateModal}>
                    {({ openModal }) => (
                      <Button label='Create Pay Rate' variant='tertiary' role='button' leftIcon={Icons.Plus} iconSize='small' action={openModal} />
                    )}
                  </ModalLauncher>
                }
              </View>
              {isShowingPayRates &&
                <View>
                  {payRatesAreLoading ?
                    <View style={{ alignItems: 'center', justifyContent: 'center', marginTop: '24px' }}>
                      <CircularSpinner size='medium' />
                    </View>
                    :
                    <View style={{ maxWidth: '100%', overflow: 'auto' }}>
                      <Table>
                        <TableHeader>
                          <TableHeaderCell>Hourly Pay Rate</TableHeaderCell>
                          <TableHeaderCell>Effective Date</TableHeaderCell>
                          <TableHeaderCell style={{ marginRight: '0px', paddingRight: '0px', width: '16px' }}></TableHeaderCell>
                        </TableHeader>
                        <TableBody>
                          {payData?.scheduleUser.payRateHistory.map((payRate) => {
                            return (
                              <TableRow key={payRate.effectiveDate}>
                                <TableCell>{payRate.hourlyPayRate}</TableCell>
                                <TableCell>{payRate.effectiveDate}</TableCell>
                                <TableCell style={{ marginRight: '0px', paddingRight: '0px', width: '16px' }}>
                                  <ModalLauncher modal={deletePayRateModal}>
                                    {({ openModal }) => (
                                      <ActionMenu>
                                        <ActionItem label='Delete' onClick={() => { openModal(payRate.effectiveDate); }} />
                                      </ActionMenu>
                                    )}
                                  </ModalLauncher>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </View>
                  }
                </View>
              }
            </View>}
          </View>
        }
      </View>
    </Card>
  );
}